// Libraries
import camelcaseKeys from 'camelcase-keys';
import { marked } from 'marked';
import { ValidationError } from 'ajv';
import { currentLanguage, LANGUAGES } from 'i18n';
import parseISO from 'date-fns/parseISO';
import QRCode from 'qrcode';
import snakeCase from 'lodash/snakeCase';
import isPlainObject from 'lodash/isPlainObject';

// Utils
import { JsonaFormatter, SchemaValidator } from 'common/utils/singletons';

// Enums
import Key from 'common/enums/Key';
import { CLASSIC, STORY } from 'common/enums/themes';

export { determineImageSize } from './commonjsHelpers';

/**
 * This takes a number and returns the ordinal version. 1 => 1st, 2 => 2nd
 * @param {number} number the number to be transformed
 * @returns {string}
 */
export const getOrdinal = (number) => {
  if (number === 0 || number === '0') return 'Unranked';

  const value = number % 100;
  const suffix = ['th', 'st', 'nd', 'rd'];

  return number + (suffix[(value - 20) % 10] || suffix[value] || suffix[0]);
};

/**
 * This generates an HTML id to be used in both a label and input,
 * generated from the label attribute of an Input model
 * @param {string} label
 */
export const generateHtmlIdForLabel = (label) =>
  label
    .replace(/[^a-z0-9\s-]/gi, '')
    .trim()
    .replace(/\s+/g, '-')
    .toLowerCase();

/**
 * Populate React Router's path with supplied parameters.
 * @param {string} path
 * @param {object} params
 */
export const populatePath = (path, params) =>
  path.replace(
    new RegExp(
      Object.keys(params)
        .map((key) => `:${key}`)
        .join('|'),
      'g'
    ),
    (key) => params[key.substr(1)]
  );

export const getNextEngagementUrl = (locale, engagementUuid) => {
  const { host } = window.location;
  const organization = host.split('.')[0];
  const isDev = window.location.href.includes('civilspace.dev');
  const engage = isDev ? 'engage-staging' : 'engage';
  return `https://${engage}.zencity.io/${organization}/${locale}/engagements/${engagementUuid}`;
};
export const getUtmParams = () => {
  let medium = 'organization_page';
  if (window.location.pathname.includes('/projects/')) {
    medium = 'project_page';
  } else if (window.location.pathname.includes('/c/')) {
    medium = 'category_page';
  } else if (window.location.pathname.includes('/categories/')) {
    medium = 'category_page';
  }
  return `?utm_source=zencity&utm_medium=${medium}`;
};

/**
 * Parse value to integer.
 * @param {string} value
 * @param {integer} radix Optional radix param
 */
export const parseInteger = (value, radix = 10) => parseInt(value, radix);

/**
 * Return number limited to the given range.
 * @param {number} value Number to clamp
 * @param {number} minValue Lower boundary
 * @param {number} maxValue Upper boundary
 */
export const clamp = (value, minValue, maxValue) => Math.max(Math.min(value, maxValue), minValue);

/**
 * Return deserialized JSON API object, with camelcased keys instead of snakecase
 * @param {Object} value
 */
export const jsonaDeserialize = (value) => {
  const camelCased = camelcaseKeys(value, { deep: true });
  return JsonaFormatter.getInstance().deserialize(camelCased);
};

/**
 * Get image dimensions.
 * @param {string} url Image URL
 * @returns {Promise}
 */
export const getImageDimensions = (url) =>
  new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = ({ target: { width, height } }) => resolve({ width, height });
    img.onerror = (error) => reject(error);
    img.src = url;
  });

/**
 * Validate data using JSON Schema validator.
 * @param {Object} schema JSON Schema definition
 * @param {Object} data Parsed json object
 * @throws {ValidationError}
 */
export const validateJson = (schema, data) => {
  const schemaValidator = SchemaValidator.getInstance();

  if (!schemaValidator.validate(schema, data)) {
    throw new ValidationError(schemaValidator.errors);
  }
};

/**
 * Apply line breaks to markdown source.
 * @param {String} source
 * @returns {String} Source with added line breaks
 */
const applyLineBreaks = (source) =>
  source.replace(
    /(?:\r\n|\n){2}((?:\r\n|\n)+)/g,
    (match, group) => `\n\n${group.replace(/\r\n|\n/g, '<br/>\n\n')}`
  );

/**
 * Parse markdown source and convert it into HTML.
 * @param {String} source
 * @returns {String} HTML output
 */
export const renderMarkdown = (source) =>
  marked(applyLineBreaks(source), {
    sanitize: false,
    gfm: true,
    tables: true,
    breaks: true,
  });

/**
 * Read file and return its content as data url.
 * @param {File} file
 * @returns {Promise}
 */
export const readFileAsDataUrl = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = ({ target: { result } }) => resolve(result);
    reader.onerror = (e) => reject(e);
    reader.onabort = (e) => reject(e);
    reader.readAsDataURL(file);
  });

/**
 * Get client's approximate location using IP lookup.
 * @returns {Promise}
 */
export const getClientLocation = async () => {
  const response = await fetch('https://ipapi.co/json');
  const { latitude: lat, longitude: lng } = await response.json();

  return { lat, lng };
};

/**
 * Test if geographical point lies inside boundary box.
 * @param {Object} point Point to test
 * @param {Number} point.lat Point latitude
 * @param {Number} point.lng Point longitude
 * @param {Object} northEastCoordinate North-east boundary point
 * @param {Number} northEastCoordinate.lat Point latitude
 * @param {Number} northEastCoordinate.lng Point longitude
 * @param {Object} southWestCoordinate South-west boundary point
 * @param {Number} southWestCoordinate.lat Point latitude
 * @param {Number} southWestCoordinate.lng Point longitude
 * @returns {boolean}
 */
export const isGeographicalPointInside = (point, northEastCoordinate, southWestCoordinate) =>
  point.lat <= northEastCoordinate.lat &&
  point.lat >= southWestCoordinate.lat &&
  point.lng <= northEastCoordinate.lng &&
  point.lng >= southWestCoordinate.lng;

/**
 * Test if image point lies inside boundary box.
 * @param {Object} point Point to test
 * @param {Number} point.x Point X coordinate
 * @param {Number} point.y Point Y coordinate
 * @param {Object} northEastCoordinate North-east boundary point
 * @param {Number} northEastCoordinate.x Point X coordinate
 * @param {Number} northEastCoordinate.y Point Y coordinate
 * @param {Object} southWestCoordinate South-west boundary point
 * @param {Number} southWestCoordinate.x Point X coordinate
 * @param {Number} southWestCoordinate.y Point Y coordinate
 * @returns {boolean}
 */
export const isImagePointInside = (point, northEastCoordinate, southWestCoordinate) =>
  point.y <= northEastCoordinate.y &&
  point.y >= southWestCoordinate.y &&
  point.x <= northEastCoordinate.x &&
  point.x >= southWestCoordinate.x;

/**
 * Return keydown event handler used to create accessible elements
 * @param {Function} callback
 */
export const getKeydownConfirmHandler = (callback) => (event) => {
  if ([Key.ENTER, Key.SPACE].includes(event.key)) {
    event.preventDefault();
    callback();
  }
};

export const featureFlagEnabled = (featureFlag) => Boolean(gon.featureFlags?.includes(featureFlag));

export const getInternetExplorerVersion = () => {
  let rv = -1; // Return value assumes failure.
  if (navigator.appName === 'Microsoft Internet Explorer') {
    const ua = navigator.userAgent;
    const re = /MSIE ([0-9]{1,}[.0-9]{0,})/;
    if (re.exec(ua) != null) rv = parseFloat(RegExp.$1);
  }
  return rv;
};

export const colors = [
  '#ff0029',
  '#377eb8',
  '#66a61e',
  '#984ea3',
  '#00d2d5',
  '#ff7f00',
  '#af8d00',
  '#7f80cd',
  '#b3e900',
  '#c42e60',
  '#a65628',
  '#f781bf',
  '#8dd3c7',
  '#bebada',
  '#fb8072',
  '#80b1d3',
  '#fdb462',
  '#fccde5',
  '#bc80bd',
  '#ffed6f',
  '#c4eaff',
  '#cf8c00',
  '#1b9e77',
  '#d95f02',
  '#e7298a',
  '#e6ab02',
  '#a6761d',
  '#0097ff',
  '#00d067',
  '#000000',
  '#252525',
  '#525252',
  '#737373',
  '#969696',
  '#bdbdbd',
  '#f43600',
  '#4ba93b',
  '#5779bb',
  '#927acc',
  '#97ee3f',
  '#bf3947',
  '#9f5b00',
  '#f48758',
  '#8caed6',
  '#f2b94f',
  '#eff26e',
  '#e43872',
  '#d9b100',
  '#9d7a00',
  '#698cff',
  '#d9d9d9',
  '#00d27e',
  '#d06800',
  '#009f82',
  '#c49200',
  '#cbe8ff',
  '#fecddf',
  '#c27eb6',
  '#8cd2ce',
  '#c4b8d9',
  '#f883b0',
  '#a49100',
  '#f48800',
  '#27d0df',
  '#a04a9b',
];

export const truncateString = (string, length) => {
  if (string.length < length) {
    return string;
  }
  return `${string.substr(0, length - 1)}...`;
};

export const urlFormatter = (url) => {
  if (!/^(https?:)?\/\//i.test(url)) {
    return `http://${url}`;
  }
  return url;
};

export const titleize = (string) => {
  const splitString = string.split(' ');
  if (!splitString) return string;
  return string
    .split(' ')
    .map((word) => `${word.charAt(0).toUpperCase()}${word.slice(1).toLowerCase()}`)
    .join(' ');
};

export const humanize = (string) => string.split('_').join(' ');

/**
 * Return distance between now and `isoDate` in hours or days (if hours > 24)
 * @param {string} isoDate
 * @returns {string}
 */
export const formatDistanceToNow = (isoDate) => {
  const miliseconds = Date.parse(isoDate) - Date.now();
  const hours = Math.max(1, Math.round(miliseconds / 1000 / 60 / 60));

  if (hours > 24) {
    return `${Math.ceil(hours / 24)} days`;
  }

  return `${hours} hour${hours > 1 ? 's' : ''}`;
};

export const storyTheme = () => gon.theme === STORY;

export const classicTheme = () => gon.theme === CLASSIC;

const escapeRegex = /`|"|'|&#39;|&quot;/g;

export const escapeCharacters = (str) => (str ? str.replace(escapeRegex, '') : str);

export const facebookShareUrl = (url) =>
  `https://facebook.com/sharer/sharer.php?u=${encodeURI(escapeCharacters(url))}`;

export const twitterShareUrl = (text, url) => {
  const escaped = escapeCharacters(text);
  return `https://twitter.com/intent/tweet?text=${encodeURI(escaped)}&url=${encodeURI(
    escapeCharacters(url)
  )}`;
};

export const linkedinShareUrl = (url) =>
  `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURI(escapeCharacters(url))}`;

export const nextdoorShareLink = (url, text) => {
  const body = text ? `${text} ${url}` : url;
  return `https://nextdoor.com/sharekit?source=zencity&hashtag=zencity&body=${encodeURI(
    escapeCharacters(body)
  )}`;
};

export const emailShareUrl = (subject, body) =>
  `mailto:?subject=${encodeURI(escapeCharacters(subject))}&body=${encodeURI(
    escapeCharacters(body)
  )}`;

export const toggleArrayValue = (array, value) =>
  array.includes(value) ? array.filter((arrayValue) => arrayValue !== value) : array.concat(value);

export const translateDateTime = (date) =>
  parseISO(date).toLocaleString(currentLanguage, {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
  });

export const translateDate = (date) =>
  parseISO(date).toLocaleString(currentLanguage, {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });

export const createMap = (data, key = 'id') =>
  data.reduce((map, item) => ({ ...map, [item[key]]: item }), {});

export const rtlLanguage = () => Boolean(LANGUAGES[currentLanguage].rtl);

export const numberRegex = (decimals) => {
  const decimalsPattern = decimals ? `(?:[.,]\\d{0,${decimals}})?` : '';
  return new RegExp(`^-?\\d*${decimalsPattern}$`);
};

/**
 * Format value with unit
 * @param {number} value
 * @param {string} unit
 * @returns {string}
 */
export const formatUnitValue = (value, unit) => {
  if (unit === '$') {
    if (value < 0) return `-${unit}${Math.abs(value).toLocaleString()}`;
    return `${unit}${value.toLocaleString()}`;
  }
  return `${value.toLocaleString()} ${unit}`;
};

/**
 * Capitalize first letter
 * @param {string} value
 * @returns {string}
 */
export const capitalize = (value) => value.charAt(0).toUpperCase() + value.slice(1);

/**
 * Round value to the nearest step
 * @param {number | string} value
 * @param {number} step
 * @param {number} min
 * @param {number} max
 * @returns {number}
 */
export const roundStepValue = (value, step, min, max) => {
  const parsedValue = Number(value);
  if (parsedValue < min) return min;

  const valueM = Math.round(parsedValue * 100);
  const stepM = Math.round(step * 100);
  const minM = Math.round(min * 100);
  const maxM = Math.round(max * 100);

  const nearestStep = (minM + stepM * Math.floor((valueM - minM) / stepM)) / 100;
  const maxValue = (minM + stepM * Math.floor((maxM - minM) / stepM)) / 100;

  return Math.min(nearestStep, maxValue);
};

export const sumQuestionValues = (values) => {
  const total = Object.values(values).reduce((sum, { value }) => sum + Number(value), 0);
  return Math.round(total * 100) / 100;
};

/**
 * Count number of decimal places
 * @param {number | string} value
 * @returns {number}
 */
export const decimalPlaces = (value) => String(value).split('.')[1]?.length || 0;

/**
 * On admin side open the new tab for display the discussion context
 * @param {(number|string)} discussionId
 */
export const openDiscussionContext = (discussionId) => {
  const language = window.location.pathname.split('/')[1];
  const url = `${window.location.origin}/${language}/admin/discussions/${discussionId}`;

  window.open(url, '_blank');
};

/**
 * @param {Number} number
 * @returns {Number}
 */
export const roundTwoDecimals = (number) => Math.round(number * 100) / 100;

/**
 * @param {string} entity
 * @returns {string}
 */
export const entityLabel = (entity) => {
  if (entity === 'announcement') return 'project update';
  if (entity === 'idea_board') return 'public board';
  return entity;
};

export const isEmbedded = () => Boolean(sessionStorage.getItem('embed'));

/**
 * Trigger the browser to download the QR Code image file
 * @param {string} data
 * @param {string} fileName
 */
export const downloadQrCode = async (data, fileName) => {
  const qrCodeData = await QRCode.toDataURL(data, { width: 1000 });
  const link = document.createElement('a');
  link.href = qrCodeData;
  link.download = fileName;
  link.click();
  link.remove();
};

/**
 * Copy data to clipboard
 * @param {string} data
 */
export const copyToClipboard = (data) => {
  const textArea = document.createElement('textarea');
  textArea.value = data;
  document.body.appendChild(textArea);
  textArea.select();
  document.execCommand('Copy');
  textArea.remove();
};

/**
 * Change keys in an object to snake case
 */
export const snakeCaseKeys = (object) => {
  if (!isPlainObject(object)) throw new Error('Not an object');

  return Object.fromEntries(
    Object.entries(object).map((entity) => {
      const key = snakeCase(entity[0]);
      const value = isPlainObject(entity[1]) ? snakeCaseKeys(entity[1]) : entity[1];

      return [key, value];
    })
  );
};

/**
 * Get the variation by locale
 * @param {Array} variations
 * @param {string} locale
 * @param {any} defaultValue
 * @returns
 */
export const getVariationByLocale = (variations, locale, defaultValue) => {
  if (!variations || !variations.length || !locale) return defaultValue;

  const found = variations?.find((variation) => variation.language === locale);

  return found || defaultValue;
};

/**
 * Get the media image urls and alt text by locale
 * @param {object} mediaObj
 * @param {string} locale
 */
export const getMediaDataByLocale = (media, locale) => {
  if (!media)
    return {
      imageUrls: {},
      altText: '',
      caption: '',
    };

  const variationInLocale = media.variations?.find((variation) => variation.language === locale);

  const data = {
    imageUrls: variationInLocale?.links?.self ? variationInLocale.imageUrls : media.imageUrls,
    altText: variationInLocale?.altText ? variationInLocale.altText : media.altText,
    caption: variationInLocale?.caption ? variationInLocale.caption : media.caption,
  };

  return data;
};
